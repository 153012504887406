import { createStore } from 'vuex'

export default createStore({
  state: {
    time_out:5,
    is_idle:false,
    user:null,
    system:{
      platform: null,
      loading: false,
    },
    reports:{
      stock_report:null,
      last_update: null,
      sales_report: [],
      suppliers: [],
      factories: [],
      order_report: null
    },
    melaket:{
      saved_search: null,
      orders: []
    },
    locations:{
      paths: [],
      updated: null,
      pallets: [],
      last_update: null
    },
    selected_order: null
  }
})

<template>
  <Loading v-if="loading"/>
  <IdleScreen v-if="is_idle" />
  <MalgesaMissions v-if="user?.role >= 20"/>
  <vue-notification-list position="top-left"></vue-notification-list>

  <div class="update-alert" v-if="show_update_alert">
    <img src="@/assets/logo_120.png" alt="" class="med-logo-img">
    <p style="font-size:24px; text-align:center; width:100%; margin-bottom:10px;">קיים עדכון מערכת חדש</p>
    <el-button type="success" style="width:320px; height:90px; margin-top: 15px; font-size:24px;" @click="update_system">עדכן עכשיו!</el-button>
  </div>

  <div class="main-router">
    <div class="home-btn" @click="go_home">
      <i class="ri-home-heart-fill"></i>
    </div>
    <div class="back-btn" @click="go_back_route" v-if="show_back">
      <i class="ri-arrow-left-circle-fill"></i>
    </div>
    <router-view/>
  </div>
  
</template>

<script>
import Loading from './components/Overlay/Loading.vue';
import { computed, defineAsyncComponent, onMounted, ref,} from '@vue/runtime-core';
import store from './store';
import {is_idle} from './functions/User'
import router from './router';
import { projectFunctions } from './firebase/config';


export default {
  components:{
    Loading,
    IdleScreen:defineAsyncComponent(() => import('./components/Overlay/IdleScreen.vue')),
    MalgesaMissions:defineAsyncComponent(() => import('./views/Wms/Malgezanim/MalgezaTasks.vue'))
  },
  setup(){
    const user = ref(computed(() => store.state.user));

    const show_update_alert = ref(false);
    let sw_detailes = null;
    let refreshing = false;

    const loading = ref(computed(() => {
      return store.state.system.loading
    }))

    const platform = ref(computed(() => {
      return store.state.system.platform
    }))

    const go_back_route = () => {
      // handle push back ops
      router.back()
    }
    
    const go_home = () => {
      router.replace('/wms/')
    }

    document.addEventListener('serviceWorkerUpdateEvent', (e) => {
      sw_detailes = e.detail;
      show_update_alert.value = true;
    }, { once: true });
    
    navigator.serviceWorker.addEventListener( 'controllerchange', () => {
      if (refreshing) return;
      refreshing = true;
      window.location.reload();
    });

    const update_system = () => {
      show_update_alert.value = false;
      if(sw_detailes  || sw_detailes.waiting){
        sw_detailes.waiting.postMessage({type:'SKIP_WAITING'});
      }
    }

    const show_back = ref(computed(() => {
      if(router.currentRoute.value.fullPath != '/' && platform.value != 'pc' && router.currentRoute.value.fullPath != '/wms/returns'){
        return true
      }else{
        return false
      }
    }))

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    if(window.innerWidth < 600){
      store.state.system.platform = 'mobile'
    }else{
      store.state.system.platform = 'pc'
    }

    onMounted(() => {
      window.addEventListener('resize', () => {
        if(document.activeElement.type && document.activeElement.type === 'text'){
          // Do not resize screen when input is clicked/activated!
          console.log("Test input, no resize.")
        }
        else{
          // Handle screen size view port VH sizing
          let vh = window.innerHeight * 0.01;
          document.documentElement.style.setProperty('--vh', `${vh}px`);
          // Handle screen size change -> Pltaform store
          if(window.innerWidth < 600){
            store.state.system.platform = 'mobile'
          }else{
            store.state.system.platform = 'pc'
          }
        }
      });
    })
 
  
    return{
      loading, go_back_route, show_back, platform,go_home,
      show_update_alert, update_system,is_idle, user
    }
    
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');
body {
    overscroll-behavior-y: contain;
}
:root{
  --main: #1f2336;
  --secondary: #54595F;
  --yellow:#fcc87f;
  --golder:#f1b35b;
  --text: #7A7A7A;
  --btnBg: #2a2c47;
  --container: #313552;
  --lightBlue: #324f5c;

  --danger: #ea5455;
  --warning: #f5953b;
  --success: #28c76f;
  --info: #01e1ff;

  --boxshadow:0 0 5px rgba(0, 0, 0, 0.148);
}
*{
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;  
  box-sizing: border-box;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  font-weight: 300;
  direction: rtl;
}
.main-router{
  position: relative;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background: var(--main);
  direction: rtl;
  overflow-x: hidden;
  padding: 3px;
}

.page-title{
  width: 100%;
  text-align: center;
  height: 50px;
  background: var(--btnBg);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #fcc87f;
  text-shadow: 0 0 2px black;
  font-size: 24px;
  margin-bottom: 5px;
  position: relative;
  flex-shrink: 0;
}

.back-btn{
  position: fixed;
  bottom: 5px;
  left: 5px;
  width: 40px;
  height: 40px;
  background: var(--btnBg);
  box-shadow: var(--boxshadow);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  color: white;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.774);
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
}
.home-btn{
  position: fixed;
  top: 15px;
  left: 8px;
  width: 40px;
  height: 40px;
  background: var(--btnBg);
  box-shadow: var(--boxshadow);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  color: white;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.774);
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
}

.update-alert{
  position: absolute;
  top: 0;
  z-index: 1000;
  left: 0;
  background: rgba(0, 0, 0, 0.85);
  color: white;
  font-size: 18px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 5px 0 5px;
  box-shadow: 0 0 10px black;
}
.med-logo-img{
  border-radius: 50%;
  margin-bottom: 25px;
}

::-webkit-scrollbar{
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 7px;
}
::-webkit-scrollbar-track{
  background: transparent;
}
::-webkit-scrollbar-thumb{
  background: linear-gradient(var(--main), var(--secondary)) ;
  border-radius: 5px;
  width: 5px;
}
::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 10px; 
}
::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 10px;
}
@media only screen and (max-width: 780px) {
  ::-webkit-scrollbar{
    width: 0;
  }
}
@media only screen and (max-width: 600px) {
  ::-webkit-scrollbar{
    width: 0;
  }
}
</style>
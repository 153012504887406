import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import locale from 'element-plus/lib/locale/lang/he';
import 'element-plus/lib/theme-chalk/index.css';
import ElementPlus from 'element-plus';

import '@dafcoe/vue-notification/dist/vue-notification.css'
import VueNotificationList from '@dafcoe/vue-notification'
import { projectAuth, projectFirestore, projectFunctions } from './firebase/config'
import { get_days_for_logout_from_db, is_user_online } from './functions/User'
import { setupInactivityTimer, stopInactivityTimer } from './functions/useIdleDetector'
import { login_log, persist_login_log } from './functions/Melaket_logs'
import Swal from 'sweetalert2'
import { log_out } from './functions/User'

function getLocation() {
  return new Promise((resolve, reject) => {
    if ("geolocation" in navigator) {
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };

      navigator.geolocation.getCurrentPosition(
        position => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          console.log('Latitude :', latitude);
          console.log('Longitude:', longitude);
          resolve({ latitude, longitude });
        },
        err => {
          console.warn(`ERROR(${err.code}): ${err.message}`);
          if (err.code === err.PERMISSION_DENIED) {
            Swal.fire({
              icon: 'warning',
              title: 'יש לאשר בדפדפן גישה למיקום.',
            })
          }
          reject(`Geolocation error: ${err.message}`);
        },
        options
      );
    } else {
      console.log("Geolocation is not supported by your browser");
      reject("Geolocation not supported");
    }
  });
}

projectAuth.onAuthStateChanged(async user => {
  if (user) {
    console.log('user: ', user);
    console.log(user.uid);

    store.state.system.loading = true;

    // Define the fetchUserProfile function to handle retries
    const fetchUserProfile = async (uid, attempts = 0) => {
      const user_profile = await projectFunctions.httpsCallable("create_profile")({ phone: user.phoneNumber });
      if (user_profile.data.locked && attempts < 3) {
        console.log(`Attempt ${attempts + 1}: User still locked, retrying...`);
        setTimeout(() => fetchUserProfile(uid, attempts + 1), 1000);
      } else {
        if (!user_profile.data.locked) {
          console.log('User profile unlocked, proceeding with authentication flow.');
          processUserProfile(user, user_profile);
        } else {
          console.log('User profile is locked after retries, logging out.');
          log_out();
        }
      }
    };

    // Initial call to fetch the user profile
    await fetchUserProfile(user.uid);
  } else {
    // User is signed out.
    if (store.state.user) {
      store.state.user = null;
    }

    if (store.state.user?.idle_triger != false) {
      stopInactivityTimer();
    }

    console.log('No user logged in');
    router.replace('/');
  }
});

async function processUserProfile(user, user_profile) {
  store.state.user = user_profile.data;
  store.state.user.role = await (await projectFunctions.httpsCallable("check_role")()).data;
  console.log(store.state.user);

  const lastRunTimestamp = localStorage.getItem('lastRunTimestamp');
  const lastAction = localStorage.getItem('lastAction');
  let location = null;

  try {
    location = await getLocation();
    console.log('Location obtained:', location);
  } catch (error) {
    console.error("Failed to get location, defaulting to null:", error);
  }

  const now = new Date().getTime();

  if (lastAction === 'login') {
    login_log(user.uid, store.state.user.name, user.metadata.lastSignInTime, location);
    localStorage.removeItem('lastAction');
    const fourDaysInMilliseconds = 4 * 24 * 60 * 60 * 1000;
    if (now - new Date(user.metadata.lastSignInTime) >= fourDaysInMilliseconds) {
      const doc = await projectFirestore.collection('Utils').doc("FourDayLogin").get();
      if (doc.exists) {
        const phones = doc.data().phones;
        projectFunctions.httpsCallable("whatsapp_text")({
          phone_list: phones,
          text: `שם משתמש: ${store.state.user.name} ${store.state.user.last_name}\nמזהה: ${store.state.user.uid}\nכניסה קודמת: ${new Date(user.metadata.lastSignInTime).toLocaleDateString('he')}, ${new Date(user.metadata.lastSignInTime).toLocaleTimeString('he')}\nהתבצעה כניסה לאחר תקופה של יותר מ 4 ימים.`
        });
      }
    }
  } else if (lastRunTimestamp && now - parseInt(lastRunTimestamp, 10) > 900000) { // 900000 ms = 15 minutes
    console.log('More than 15 minutes since last run, calling persist_login_log()');
    persist_login_log(user.uid, store.state.user.name, now, location);
  }

  localStorage.setItem('lastRunTimestamp', now.toString());

  store.state.system.loading = false;
  store.state.time_out = await get_days_for_logout_from_db();
  is_user_online(user.uid, true);

  console.log("Idle triger: " + store.state.user.idle_triger);
  if (store.state.user?.idle_triger != false) {
    setupInactivityTimer(store.state.time_out);
  }
  if (user.uid == "xZJbSgXoKnVepaJDXawimCGzYHN2") {
    router.push("/wms/printstation");
  }
}



const app = createApp(App);

// app.config.errorHandler = (err, vm, info) => {
//   console.error('Captured an error globally:', err);
//   // Handle the error, e.g., send it to an error tracking service
// };

app
  .use(store)
  .use(router)
  .use(ElementPlus, { locale })
  .use(VueNotificationList)
  .mount('#app');

// createApp(App)
// .use(store)
// .use(router)
// .use(ElementPlus, {locale:locale})
// .use(VueNotificationList)
// .mount('#app').config.errorHandler = (err, vm, info) => {
//     console.error('Captured an error globally:', err);
//     // Handle the error, e.g., send it to an error tracking service
// }